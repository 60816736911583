import React from 'react';
import { AccessoryCard } from '~/pages/model-accessories/components/accessory-card';
import { Accessory } from '~/common/models';
import styles from './index.module.scss';

type AccessoriesWrapperProp = { accessories: Accessory[] };
export const AccessoriesWrapper = React.memo<AccessoriesWrapperProp>(
  ({ accessories }) => {
    return (
      <div
        style={{ gridTemplateColumns: `repeat(${accessories.length}, 1fr)` }}
        className={styles.topAccessoriesWrapper}
      >
        {accessories.map((acc) => (
          <AccessoryCard key={acc.id} {...acc} backgroundColor="#FFF" />
        ))}
      </div>
    );
  }
);
