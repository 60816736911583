import React, { useEffect } from 'react';
import { Loading } from '@ui-elements';
import { useRecoilValueLoadable, useRecoilRefresher_UNSTABLE } from 'recoil';
import {
  ModelAccessoryPacksQuery,
  TopAccessoriesQuery,
} from '~/pages/model-accessories/store';
import { AccessoriesWrapper } from './accessories-content';
import { AccessoryPacksCarouselWrapper } from './accessory-packs-content';
import styles from './index.module.scss';

export const ModelAccessories = ({ modelPimIdentifier, accessories }) => {
  const accessoryPacksLoadable = useRecoilValueLoadable(
    ModelAccessoryPacksQuery(modelPimIdentifier)
  );
  const refreshAccessoryPacks = useRecoilRefresher_UNSTABLE(
    ModelAccessoryPacksQuery(modelPimIdentifier)
  );

  const accessoriesLoadable = useRecoilValueLoadable(
    TopAccessoriesQuery(accessories)
  );
  const refreshAccessories = useRecoilRefresher_UNSTABLE(
    TopAccessoriesQuery(accessories)
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        accessoriesLoadable.state === 'loading' &&
        accessoryPacksLoadable.state === 'loading'
      ) {
        accessoriesLoadable.errorOrThrow();
        accessoryPacksLoadable.errorOrThrow();
      }
    }, 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const refresh = () => {
    refreshAccessoryPacks();
    refreshAccessories();
  };

  if (
    accessoriesLoadable.state === 'loading' ||
    accessoryPacksLoadable.state === 'loading'
  ) {
    return <Loading height="100%" />;
  }

  if (
    accessoriesLoadable.state === 'hasValue' &&
    accessoryPacksLoadable.state === 'hasValue'
  ) {
    return (
      <>
        <AccessoryPacksCarouselWrapper
          accessoryPacks={accessoryPacksLoadable.getValue()}
        />
        <AccessoriesWrapper accessories={accessoriesLoadable.getValue()} />
      </>
    );
  }

  return (
    <div className={styles.accessoryLoadError}>
      <p>
        Sorry, we were unable to load accessories at this time.
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            refresh();
          }}
        >
          Please click here to try again.
        </a>
      </p>
    </div>
  );
};
