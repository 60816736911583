import React from 'react';
import { RecoilRoot } from 'recoil';
import { Button } from '@ui-elements';
import { ModelAccessories } from './model-accessories';
import styles from './index.module.scss';
import cn from 'classnames';

interface AccessoriesProp {
  contentId: string;
  heading: string;
  subHeading: string;
  urlSegment: string;
  modelPimIdentifier: string;
  accessories: string[];
}

export default ({
  contentId,
  heading,
  subHeading,
  urlSegment,
  modelPimIdentifier,
  accessories,
}: AccessoriesProp) => {
  return (
    <RecoilRoot>
      <div
        id={`cid-${contentId}`}
        className={cn('full-device-width', styles.accessories)}
      >
        <div className={styles.headerContent}>
          <h2>{heading}</h2>
          <div dangerouslySetInnerHTML={{ __html: subHeading }} />
        </div>

        <div className={styles.accessoriesContent}>
          <div>
            {modelPimIdentifier && accessories.length > 0 && (
              <ModelAccessories
                accessories={accessories}
                modelPimIdentifier={modelPimIdentifier}
              />
            )}
          </div>
        </div>

        <div className={styles.accessoriesCta}>
          <Button
            className={styles.shopAllAccessoriesBtn}
            buttonSize="large"
            href={`/${urlSegment}/accessories`}
          >
            Shop all accessories
          </Button>
        </div>
      </div>
    </RecoilRoot>
  );
};
